body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
a:focus {
    box-shadow: none !important;
    /* border-color: transparent !important; */
}
.navbar .navbar-collapsable .navbar-nav li a.nav-link {
    color: #f90;
}
:focus:not(.focus--mouse),
.form-check [type="checkbox"]:focus + label,
.form-check [type="radio"]:focus + label,
.toggles label input[type="checkbox"]:focus + .lever {
    box-shadow: none !important;
    border-color: inherit !important;
}
.single-line {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
