.selectedClass {
    display: flex;
    gap: 0.5rem;
    margin-top: 0.5rem;
    & .tag-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0.2rem;
        height: 2rem;

        border-radius: 4px;
        background-color: #f90 !important;
        color: white;
        padding: 0.2rem;
    }
}

.remove-btn {
    height: 2rem;
    width: 1rem;
    background-color: #f90 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    border: none;
}
